body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Arsenal', sans-serif;
  scroll-behavior: smooth;
}
body,
html,
#root {
  width: 100%;
  height: 100%;
}
h4 {
  font-weight: bold;
  font-size: 18px;
}
h3 {
  font-weight: bold;
  font-size: 20px;
}
.container {
  width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-areas: 'header' 'menu' 'content' 'footer';
  grid-template-rows: 170px 80px 1fr 150px;
  grid-template-columns: 1fr;
}
header {
  height: 170px;
  position: relative;
  overflow: hidden;
}
svg text#string-note-3 {
  color: blue;
}
.sun {
  position: absolute;
  width: 720px;
  margin-left: -360px;
  left: 50%;
  top: 30px;
  z-index: 1;
}
.logo-title {
  font-size: 48px;
  margin: 0;
  padding: 0;
  font-family: 'Ruslan Display', cursive;
}
.logo-subtitle {
  margin: 0;
  padding: 0;
  font-family: 'Neucha', cursive;
  position: absolute;
  top: 43px;
  font-weight: bold;
  left: 65px;
}
.header-divider {
  top: 173px;
  position: absolute;
  height: 50px;
  z-index: 3;
  left: 50%;
  width: 1100px;
  margin-left: -673px;
}
.logo-text {
  position: absolute;
  text-align: center;
  width: 180px;
  height: 98px;
  left: 50%;
  top: 75px;
  margin-left: -95px;
  z-index: 2;
}
.logo-text:hover {
  text-decoration: none;
}
.main-menu {
  padding: 0;
  text-align: center;
  margin: 8px 5px 4px;
}
.main-menu li {
  display: inline-block;
  margin: 0;
  padding: 0 16px;
}
.main-menu li a {
  font-family: 'Ruslan Display', cursive;
  color: #aa0000;
  text-decoration: none;
  font-size: 20px;
}
.main-menu .link-active {
  color: red;
}
.header-selector {
  font-family: 'Ruslan Display', cursive;
  text-align: center;
  width: 140px;
  position: absolute;
  top: 70px;
  color: #000000;
  left: 50%;
}
.header-selector-left {
  margin-left: -500px;
}
.header-selector-right {
  right: 32px;
  margin-left: 375px;
}
.selected-value {
  display: block;
  text-align: center;
  color: #aa0000;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  text-decoration: underline;
}
.sidebar-title {
  font-family: 'Ruslan Display', cursive;
}
a {
  color: #662f0a;
  text-decoration: none;
  font-weight: bold;
}
a:hover {
  text-decoration: underline;
}
.lessons-menu {
  background: #FFE0B2;
  padding: 16px;
  border-radius: 2px;
}
.lessons-menu__item {
  padding: 3px 0;
  list-style: none;
  font-weight: bold;
  font-size: 18px;
}
.lessons-menu__item-link-active {
  color: red;
}
.lesson-footer {
  padding: 16px 0;
  text-align: center;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.chord-container {
  padding: 16px;
  display: inline-block;
}
.lesson-block {
  display: block;
  padding-top: 16px;
}
.lesson-block__chord {
  width: 200px;
  display: block;
  margin: 0 auto;
}
.lesson-block__description {
  padding: 16px;
  display: block;
  text-align: center;
  font-style: italic;
  font-size: 12px;
}
.lesson-table {
  border: 1px solid #662f0a;
  border-collapse: collapse;
  margin: 0 auto;
}
.lesson-table td {
  border: 1px solid #662f0a;
  text-align: center;
  width: 60px;
}
.lesson-table td {
  padding: 8px;
}
.song-row-prelude {
  position: absolute;
  right: 100%;
}
.master-card {
  width: 370px;
  display: inline-block;
  vertical-align: top;
  margin-right: 16px;
  margin-bottom: 16px;
}
.mobile-header {
  display: none;
}
