/* ruslan-display-regular - latin_cyrillic */
@font-face {
  font-family: 'Ruslan Display';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/ruslan-display-v10-latin_cyrillic-regular.eot');
  /* IE9 Compat Modes */
  src: local('Ruslan Display'), local('RuslanDisplay'), url('/fonts/ruslan-display-v10-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/ruslan-display-v10-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/ruslan-display-v10-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */ url('/fonts/ruslan-display-v10-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/ruslan-display-v10-latin_cyrillic-regular.svg#RuslanDisplay') format('svg');
  /* Legacy iOS */
}
/* arsenal-regular - cyrillic-ext_latin_cyrillic */
@font-face {
  font-family: 'Arsenal';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/arsenal-v4-cyrillic-ext_latin_cyrillic-regular.eot');
  /* IE9 Compat Modes */
  src: local('Arsenal Regular'), local('Arsenal-Regular'), url('/fonts/arsenal-v4-cyrillic-ext_latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/arsenal-v4-cyrillic-ext_latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */ url('/fonts/arsenal-v4-cyrillic-ext_latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/arsenal-v4-cyrillic-ext_latin_cyrillic-regular.svg#Arsenal') format('svg');
  /* Legacy iOS */
}
/* arsenal-700 - cyrillic-ext_latin_cyrillic */
@font-face {
  font-family: 'Arsenal';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/arsenal-v4-cyrillic-ext_latin_cyrillic-700.eot');
  /* IE9 Compat Modes */
  src: local('Arsenal Bold'), local('Arsenal-Bold'), url('/fonts/arsenal-v4-cyrillic-ext_latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/arsenal-v4-cyrillic-ext_latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */ url('/fonts/arsenal-v4-cyrillic-ext_latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/arsenal-v4-cyrillic-ext_latin_cyrillic-700.svg#Arsenal') format('svg');
  /* Legacy iOS */
}
/* arsenal-italic - cyrillic-ext_latin_cyrillic */
@font-face {
  font-family: 'Arsenal';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/arsenal-v4-cyrillic-ext_latin_cyrillic-italic.eot');
  /* IE9 Compat Modes */
  src: local('Arsenal Italic'), local('Arsenal-Italic'), url('/fonts/arsenal-v4-cyrillic-ext_latin_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/arsenal-v4-cyrillic-ext_latin_cyrillic-italic.woff') format('woff'), /* Modern Browsers */ url('/fonts/arsenal-v4-cyrillic-ext_latin_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/arsenal-v4-cyrillic-ext_latin_cyrillic-italic.svg#Arsenal') format('svg');
  /* Legacy iOS */
}
/* neucha-regular - latin_cyrillic */
@font-face {
  font-family: 'Neucha';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/neucha-v11-latin_cyrillic-regular.eot');
  /* IE9 Compat Modes */
  src: local('Neucha'), url('/fonts/neucha-v11-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/neucha-v11-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */ url('/fonts/neucha-v11-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/neucha-v11-latin_cyrillic-regular.svg#Neucha') format('svg');
  /* Legacy iOS */
}
/* fira-mono-regular - latin_cyrillic */
@font-face {
  font-family: 'Fira Mono';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/fira-mono-v8-latin_cyrillic-regular.eot');
  /* IE9 Compat Modes */
  src: local('Fira Mono Regular'), local('FiraMono-Regular'), url('/fonts/fira-mono-v8-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/fonts/fira-mono-v8-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('/fonts/fira-mono-v8-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */ url('/fonts/fira-mono-v8-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('/fonts/fira-mono-v8-latin_cyrillic-regular.svg#FiraMono') format('svg');
  /* Legacy iOS */
}
