@media only screen and (max-device-width: 768px) {
  .container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas: 'header' 'content' 'footer';
    grid-template-rows: 70px 1fr 100px;
    grid-template-columns: 1fr;
  }
  header {
    height: 68px;
  }
  .sun,
  .logo-text,
  .menu,
  .header-selector {
    display: none;
  }
  .page-content {
    padding: 0 16px;
    text-align: justify;
  }
  .master-card {
    width: 100%;
    float: none;
  }
  .lesson-block__chord {
    width: 100%;
  }
  .lesson-table {
    font-size: 12px;
  }
  .mobile-header {
    padding: 12px;
    display: grid;
    height: 55px;
    grid-template-rows: 1fr;
    grid-template-columns: 32px 1fr 32px;
    grid-gap: 2vw;
    border-bottom: 1px solid #999;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    background: #8a3501;
  }
}
